import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layout/layout';
import ReactMarkdown from 'react-markdown';
import { GatsbyTemplateProps } from '../../../common/interfaces/gatsby-template-props.interface';
import { PressReleaseContext } from './press-release.gatsby';
import PressReleaseContactInfo from '../components/press-release-contact-info';
import { Helmet } from 'react-helmet';
import { Locale } from '../../../common/util/locales';
import { getMetaTagsJSX } from '../../page/page.template';
import { buildURL } from '../../layout/refreshed-header/HrefLangs';
import { MetaTags } from '../../../contentful/content-types/elements/meta-tags.interface';
import { useCMSState } from '@internal/useCMSHook';
import { useConfiguration } from '../../../common/hooks/useConfiguration';
import { MainFooter } from '../../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../../contentful/content-types/nav-navigation-group';

// Export query for gatsby to infer from template when gatsby-node invokes `createPage`
export const pageQuery = graphql`
  query($id: String!, $locale: String!) {
    pressRelease(id: { eq: $id }) {
      id
      title
      contentfulId
      metaTags {
        metaTitle
        metaDescription
        twitterCustomImage {
          file {
            publicURL
          }
        }
        metaImage {
          file {
            publicURL
          }
        }
        facebookCustomImage {
          file {
            publicURL
          }
        }
      }
      subtitle
      orderDate
      body
      omitBoilerplate
      boilerplate
    }
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    baseURL: site {
      siteMetadata {
        baseURL
      }
    }
  }
`;

export interface PressReleaseDTO {
  id: string;
  locale: Locale;
  contentfulId: string;
  title: string;
  subtitle: string;
  metaTags?: MetaTags;
  orderDate: string;
  body: string;
  omitBoilerplate: boolean;
  boilerplate: string;
}

export interface PressReleaseData extends GatsbyTemplateProps<PressReleaseContext> {
  data: {
    pressRelease: PressReleaseDTO;
    headerData: ContentfulNavNavigationGroup;
    footerData: MainFooter;
    baseURL: any;
  };
}

const PressReleaseTemplate: FC<PressReleaseData> = ({ data, pageContext }: PressReleaseData) => {
  const config = useConfiguration();
  let { footerData, headerData, pressRelease } = data;

  if (config.targetEnv === 'staging') {
    footerData = useCMSState<MainFooter>(footerData);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    pressRelease = useCMSState<PressReleaseDTO>(pressRelease);
  }

  const { title, subtitle, body, boilerplate } = pressRelease;
  const url = buildURL(pageContext.locale, pageContext.pathname, pageContext.baseURL);
  const baseURL = data.baseURL?.siteMetadata?.baseURL || 'https://www.cloudflare.com';

  const fallbackMetaTags = [
    <meta name="title" content={title} />,
    <meta name="description" content={subtitle || title} />,
    <meta name="twitter:title" content={title} />,
    <meta name="twitter:description" content={subtitle || title} />,
    <meta property="og:title" content={title} />,
    <meta property="og:url" content={url} />,
    <meta property="og:description" content={subtitle || title} />,
  ];

  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>Cloudflare | Press Releases</title>
        {pressRelease.metaTags ? getMetaTagsJSX(baseURL, pressRelease.metaTags) : fallbackMetaTags}
      </Helmet>
      <section className="tile tile--borderless">
        <div className="tile__content tile__content--fixed">
          <div className="grid">
            <div className="grid__container">
              <div className="grid__item grid__item--two-thirds">
                <h1 className="marketing-h2 marketing-h2--long-title marketing-h2--left marketing-h2--margin-bottom">
                  {title}
                </h1>
                <h2 className="marketing-subtitle marketing-subtitle--margin-top">{subtitle}</h2>
                <span className="img-scaling" itemProp="articleBody">
                  <ReactMarkdown source={body} escapeHtml={false} />
                  {!!boilerplate ? <ReactMarkdown source={boilerplate} escapeHtml={false} /> : null}
                </span>
              </div>
              <div className="grid__item grid__item--third">
                <PressReleaseContactInfo variant="press-releases" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PressReleaseTemplate;
